<template>
  <div class="home">
    <div class="wrap-home">
      <v-carousel v-model="actiCarru" height="100vh" cycle hide-delimiter-background show-arrows-on-hover continuous>
        <div class="filter">
          <img src="@/assets/logoBienvenida.png" class="logo-bien" width="300px" height="300px" />
        </div>
        <template v-slot:prev="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on"> <v-icon color="primary">
              fa-solid fa-chevron-left


            </v-icon></v-btn>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-btn color="primary" icon v-bind="attrs" v-on="on"><v-icon>
              fa-solid fa-chevron-right
            </v-icon></v-btn>
        </template>
        <v-carousel-item v-for="(img, i) in imgCarruHome" :key="i" :src="img.src" reverse-transition="fade-transition"
          transition="fade-transition">

        </v-carousel-item>
      </v-carousel>


    </div>
    <!-- <v-container class="mt-12">
      <h1 class="text-center color-primary">
        GUTIERREZ Y ASOCIADOS S. DE R.L. DE C.V.  
      </h1>
      <p class="text-center color-primary mb-4">
        Arquitectos, Ingenieros, Consultores y Constructores
      </p>
      <p class="text-center">
        Es una empresa Hondureña de capital privado dedicada a la consultoría y
        construcción de obras civiles y de arquitectura. <br /><br />
        Sus labores se inician a finales de 1990 en Tegucigalpa, Honduras, cuyo
        los socios fundadores empiezan a trabajar en forma conjunta. La empresa
        se constituye legalmente en Mayo de 1994 y a partir de esa misma fecha
        se encuentra inscrita en el Colegio de Arquitectos de Honduras (CAH
        90054), Colegio de Ingenieros Civiles de Honduras (CICH 866 123 N CT/CS)
        y Colegio de Ingenieros Mecánicos, Electricistas y Químicos de Honduras
        (CIMEQH).
        <br /><br />

        La Empresa ha participado activamente en la ejecución de proyectos de
        pequeña, mediana y gran envergadura entre los que cuentan: Residencias,
        Edificios de tipología Comercial, Industrial, Bancaria, Educacional,
        Pública-Gubernamental, Recreativa, Turística y otros.
      </p>
    </v-container> -->


    <v-container class="mb-12">
      <h1 class="color-primary text-center mb-4">PROYECTOS</h1>

      <v-tabs v-model="tab" align-with-title fixed-tabs>
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab v-for="(item, index) in items" :key="index">
          {{ item.title }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, i) in items" :key="i">
          <v-card flat class="mb-6 pa-2">
            <v-row class="mt-12">
              <v-col v-for="(n, a) in item.proyect" :key="a" class="d-flex child-flex" cols="12" md="6" sm="6">
                <v-card class="mx-auto" @click="setInfoOpenDialog(n)">
                  <v-img class="white--text align-end" height="200px" :src="n.galeryProyect[0]">
                    <v-card-title>
                      <b class="box-text">{{ n.nameProyect }}</b></v-card-title>
                  </v-img>

                  <v-card-text class="pb-0">
                    <v-chip class="" v-if="n.type" small>{{ n.type }}</v-chip>
                    <v-card-subtitle class="pb-0">{{
                      n.subtitle
                    }}</v-card-subtitle>
                    <v-card-text class="text--primary">
                      <div>{{ n.year }}</div>
                      <div>{{ n.area }}</div>
                    </v-card-text>
                  </v-card-text>

                  <!-- <v-card-actions>
                    <v-btn
                      block
                      color="primary"
                      style="border-radius: 0px"
                   
                    >
                      ver más
                    </v-btn>
                  </v-card-actions> -->
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <v-container class="mision-vision mt-12">
      <h1 class="text-center color-primary mb-4">VISIÓN Y MISIÓN</h1>
      <v-divider> </v-divider>
      <v-row class="mt-4">
        <v-col cols="12" md="6" sm="12" lg="6">
          <p class="xs-text-center">
            <b class="color-primary">MISIÓN:</b> <br class="xs-block" />
            Prestar servicios profesionales de consultoría y construcción de
            proyectos de arquitectura e ingeniería civil, los cuales se
            caracterizan por la atención personalizada al cliente y la alta
            calidad de nuestro producto terminado. <br />
            <br />

            Ser una empresa generadora de empleos, considerando nuestro recurso
            humano como uno de nuestros grandes potenciales para alcanzar
            nuestras metas de servicio y calidad.
          </p>
        </v-col>
        <v-col cols="12" md="6" sm="12" lg="6">
          <p class="xs-text-center">
            <b class="color-primary">VISIÓN:</b> <br class="xs-block" />
            Alcanzar el liderazgo en el mercado local del Diseño y la
            Construcción de proyectos con nuestros servicios de atención
            personalizada y desarrollo de trabajos con esmerada calidad,
            eficiencia y costo moderado. <br /><br />
            Innovar continuamente y buscar nuevas tecnologías que permitan
            realizar procesos de construcción de forma más rápida, eficiente,
            con mejor calidad y causando el menor daño ambiental posible.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="my-12">
      <v-card color="primary" dark class="pa-6">
        <v-row>
          <v-col cols="12" md="6" sm="6" lg="6">
            <h1 class="mb-4">Contáctanos</h1>

            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field v-model="form.name" dark label="Nombre" :rules="nameRules" name="name" required
                filled></v-text-field>

              <v-text-field v-model="form.email" :rules="emailRules" label="Correo" required name="email"
                filled></v-text-field>
              <v-text-field v-model="form.phone" name="numero" label="Celular" :rules="nameRules" required
                filled></v-text-field>
              <v-textarea label="Comentario" v-model="form.message" :rules="nameRules" name="message" filled></v-textarea>

              <input type="hidden" name="_next" value="https://gutierrez-asocciados.web.app/" />
              <input type="hidden" name="_captcha" value="false" />
              <v-btn class="mr-4" block :disabled="!valid" @click="validate" light height="50">
                Enviar
              </v-btn>
            </v-form>
          </v-col>
          <v-col cols="12" md="6" sm="6" lg="6">
            <v-img src="@/assets/home-0023.jpg" height="100%"> </v-img>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="mb-12">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d967.5681025884627!2d-87.23497426025067!3d14.061084741159455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f6fbd72f05314ad%3A0xd5276b5b99ad348b!2sGutierrez%20%26%20Asociados.!5e0!3m2!1sen!2shn!4v1666243834466!5m2!1sen!2shn"
        width="100%" height="300" style="border: 0" allowfullscreen="" loading="lazy" class="imframe"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </v-container>
    <v-dialog transition="dialog-bottom-transition" v-model="dialogInfo" v-if="dialogInfo" scrollable fullscreen>
      <infoProyect @closed="dialogInfo = false" :info="setInfo" />
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import infoProyect from "@/components/infoProyect.vue";
import axios from "axios";
export default {
  name: "Home",
  components: {
    infoProyect,
  },
  data() {
    return {
      setInfo: null,
      dialogInfo: false,
      actiCarru: 0,
      valid: true,
      form: {
        name: "",
        email: "",
        phone: null,
        message: "",
      },
      nameRules: [(v) => !!v || "Campo Requerido"],
      email: "",
      emailRules: [
        (v) => !!v || "Campo Requerido",
        (v) => /.+@.+\..+/.test(v) || "Correo no valido",
      ],
      select: null,
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,
      imgCarruHome: [
        { src: require("@/assets/Carrusel/home1.jpg") },
        { src: require("@/assets/Carrusel/home4.jpg") },
        { src: require("@/assets/Carrusel/home2.jpg") },
        { src: require("@/assets/Carrusel/home5.jpg") },
        { src: require("@/assets/Carrusel/home3.jpg") },
        // { src: require("@/assets/home-2.jpeg") },
        // { src: require("@/assets/home-0022.jpg") },
        // { src: require("@/assets/home-1.jpeg") },
        // { src: require("@/assets/home-3.jpeg") },
        // { src: require("@/assets/home-0023.jpg") },
      ],
      tab: null,
      items: [
        {
          title: "EDUCATIVO",
          proyect: [
            {
              nameProyect: "DISCOVERY SCHOOL",
              subtitle: "CAMPUS ACADÉMICO",
              type: "CONSTRUCCIÓN",
              year: "2006",
              location: "Zona El Molino, Tegucigalpa M.D.C",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  2,326.00 m² (25,036.86 pie²)",
              //               textInfo: `Construcción de edificios para el campus de la escuela Discovery School, a base de
              // estructuras de concreto reforzado, entrepisos metálicos con joist mas lámina tipo losa cero, techos de estructura metálica y cubierta de láminas metálicas pre-pintadas imitación teja, paredes de bloque de concreto tipo split face con acabado expuesto, entrepisos de estructura metálica y fundición de concreto, firmes de piso de concreto reforzado y superficie de porcelanato, acabados interiores con repellos de color integral (estucados), divisiones de tablayeso, cielos acústicos de suspensión con láminas de fibra mineral, puertas de madera de color, ventanas de aluminio color blanco y vidrio y de PVC y vidrio.
              // El conjunto consta de 8 edificios entre los que se encuentran: Preescolar, Primaria,
              // Secundaria, Gimnasio y Cafetería, además de los espacios de Cancha polideportiva,
              // recorridos peatonales y Estacionamiento vehicular.
              // `,
              price: "$ 1,880,850.00",
              galeryProyect: [
                { src: require("@/assets/home-1.jpeg") },
                { src: require("@/assets/proyecto-1-02.jpeg") },
                { src: require("@/assets/proyecto-1.jpeg") },
              ],
            },
            {
              nameProyect: "UNIVERSIDAD JOSÉ CECILIO DEL VALLE",
              subtitle: "EDIFICIO ACADÉMICO ETAPA 1 Y 2",
              type: "DISEÑO Y CONSTRUCCIÓN ",
              year: "2010-2018",
              location: "Colonia Humuya, Tegucigalpa M.D.C",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total: 22,875 m² (246,224.45 pie²) ",
              //               textInfo: `La Universidad José Cecilio del Valle (UJCV), cuenta con un edificio de siete pisos, el cual contiene, auditorio con capacidad para 110 personas, sala multifuncional para 250 personas, 24 cubículos para profesores, aulas para clases, oficinas para administración académica, gimnasio y cancha deportiva, módulos de baño en todas las plantas, salas eléctricas y de mantenimiento. 
              // Estructura de concreto reforzado en columnas, vigas de techo y viguetas KJ prefabricadas. Muros de contención de mampostería, bloque reforzado de 8 "y pantalla de concreto anclado “Shotcrete”. Paredes de bloques pulidos y pintados de 6 ", tejas metálicas de tejas más techos de acero, techos abovedados, cielo falso de tabla-yeso, ventanas y división de paredes en aluminio anodizado bronce con vidrio bronce reflectante y sombrillas ACM, pisos y paredes de porcelanato. Instalaciones electromecánicas completas con generador de respaldo y transferencia automática, incluye unidades de A / C.
              // `,
              price: "Valor de la Construcción: $ 4,280,000.00",
              galeryProyect: [
                { src: require("@/assets/chilo.jpg") },
                { src: require("@/assets/vertical-2.jpeg") },
                { src: require("@/assets/JSECHILO.jpeg") },
              ],
            },
            {
              nameProyect: "UNIVERSIDAD JOSÉ CECILIO DEL VALLE COMAYAGUA",
              subtitle: "EDIFICIO ACADÉMICO ETAPA 1 Y 2",
              type: "DISEÑO PLAN MAESTRO Y CONSTRUCCIÓN ",
              year: "2012",
              location: "Comayagua, Comayagua.",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total: 2,875.00 m² (30,946.00 pie²) ",
              //               textInfo: `El campus tiene lo siguiente: 215 plazas de estacionamiento, 18 aulas en tres módulos de 6 aulas cada uno, dos módulos administrativos, física, química y laboratorios académicos, biblioteca, librería, enfermería, salón de juntas y maestros, cafetería, cancha de deportes, auditorio, tres módulos de baños, cabinas de vigilancia, sala eléctrica y de bombas, circulación al aire libre con asientos, porche de acceso principal.  

              // Cimentaciones, columnas de concreto reforzado prefabricado y vigas pretensadas, pisos de cerámica, puertas y ventanas exteriores de aluminio y bronce, puertas interiores de fibra de madera sintética más revestimiento, revestimientos en paredes interiores con base inferior de madera sintética y paneles de yeso en la parte superior, aislamiento térmico con fibra R-11, estructura de techo con tijeras de madera y paneles de madera sintética, tipo de hoja de aluzinc para el techo.

              // `,
              price: "Valor de la Construcción: $ 1,600,000.00",
              galeryProyect: [
                { src: require("@/assets/chiloComayagua/photo-01.jpg") },
                { src: require("@/assets/chiloComayagua/photo-02.jpg") },
                { src: require("@/assets/chiloComayagua/photo-03.jpg") },
                { src: require("@/assets/chiloComayagua/photo-04.jpg") },
                { src: require("@/assets/chiloComayagua/photo-05.jpg") },
              ],
            },
            {
              nameProyect: "EDIFICIO DE TALLERES ",
              subtitle: "VILLA DE LAS NIÑAS",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2014",
              location: "Colonia Villa Peniel, Carretera a Olancho Km. 8.",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total: 1,102.00 m² (11,846.50 pie²)",
              //               textInfo: `Villas de las niñas es un internado para niñas donde reciben una beca que garantiza una alta educación académica. 

              // El edificio cuenta en primer piso: laboratorio de química y 2 salones para la escuela secundaria. Segundo piso: 3 clases para la escuela secundaria. Tercer piso: sala de computadoras con aire acondicionado y sala de capacitación para call center además incluye un modelo de escaleras que articula ambos edificios.
              // Estructura en columnas y vigas de concreto reforzado, bloques de paredes de mampostería, viguetas metálicas para las vigas secundarias, láminas aluzinc estructurales y losa  reforzado de concreto premezclado. Estructura de cubierta metálica y cubierta de lámina de aluzinc pre-pintada, paredes exteriores cubiertas de piedra y paredes interiores revestidas de cerámica blanca, y las paredes interiores con revestimiento pulido, y pintura, ventanas de PVC con vidrio transparente, coloreadas con panel de madera sintética para las puertas revestidas a ambos lados. Barandas con estructura metálica y pasamanos de acero inoxidable, techo pintado con tablero de yeso y pisos de cerámica. Escalera cubierta con lámina de policarbonato. 

              // `,
              price: "Valor de la Construcción: $ 676,160.00",
              galeryProyect: [
                { src: require("@/assets/niñas/photo-01.jpg") },
                { src: require("@/assets/niñas/photo-02.jpg") },
                { src: require("@/assets/niñas/photo-03.jpg") },
                { src: require("@/assets/niñas/photo-04.jpg") },
                { src: require("@/assets/niñas/photo-05.jpg") },
              ],
            },
            {
              nameProyect: "EDIFICIO DE TALLERES ",
              subtitle: "VILLA DE LOS NIÑOS",
              type: "PLAN MAESTRO / DISEÑO Y PLANIFICACIÓN",
              year: "2016",
              location: "Valle de Amarateca, Francisco Morazán",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  23,960 m² (257,903.29 pie²)",
              //               textInfo: `Villa de Los Niños es un centro educativo con modalidad de  internado en los grados de 7mo a 11vo, para niños en condiciones de escasos recursos, cuyas edades oscilan entre 12 y 18 años. Los niños disfrutan de una beca integral que garantiza una alta educación académica, capacitación técnica, formación espiritual con excelente principios de moralidad y ética, así como alojamiento, alimentación, vestimenta, materiales académicos y suministros, atención médica, odontológica y psicológica, entre otros beneficios. 

              // El centro educativo tiene una capacidad para 600 estudiantes (en 1era etapa) y un total de 1,200, además del personal religioso, administrativo, docente y de mantenimiento que suma aproximadamente 35 personas en el complejo. ETAPA 1 consiste en: Trabajos de urbanización (áreas públicas, calles, campos deportivos y áreas recreativas.) Edificio Académico y Dormitorios. Edificio de Talleres y Biblioteca, Taller de mecánica automotriz y soldadura industrial, Gimnasio y Salón de Usos Múltiples con escenario y áreas para vestidores, ensayos de danza y orquestina.`,
              price: "Valor de la Construcción: $5,932,000.00",
              galeryProyect: [
                { src: require("@/assets/ninos/photo-01.jpg") },
                { src: require("@/assets/ninos/photo-02.jpg") },
                { src: require("@/assets/ninos/photo-03.jpg") },
                { src: require("@/assets/ninos/photo-04.jpg") },
                { src: require("@/assets/ninos/photo-05.jpg") },
                { src: require("@/assets/ninos/photo-06.jpg") },
              ],
            },
            {
              nameProyect: "TALLER DE MECÁNICA AUTOMOTRIZ",
              subtitle: "VILLA DE LAS NIÑOS",
              type: null,
              year: "2016",
              location: "Valle de Amarateca, Francisco Morazán",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total: 720.00 m² (7,750.00 pie²)",
              //               textInfo: `Construcción de Edificio de Taller de mecánica automotriz con los siguientes espacios: 
              // Área para taller de mecánica, enderezado y pintado, área para elevadores, alineadores y balanceadoras, bodega de repuestos, aulas de capacitación y módulo de baños.
              // \n
              // Construcción de concreto reforzado con zapatas aisladas y tensores, columnas y vigas de concreto reforzado acabado aparente, paredes de bloque y persianas metálicas de platina, estructura de techos con armaduras de perfil metálico WF, clavadores de canaleta y cubierta de lámina de aluzinc pre-pintada color blanco. 
              // \n
              // Pisos de concreto aparente acabado afinado, enchapes en paredes con cerámica de color en interiores y enchapes de piedra aserrada en exteriores. Ventanas de PVC color blanco y persianas metálicas de platina. Instalaciones especiales HG para aire comprimido e instalaciones hidrosanitarias completas.
              // `,
              price: "Valor de la Construcción: $ 587,840.00",
              galeryProyect: [
                { src: require("@/assets/VN2/photo-01.jpg") },
                { src: require("@/assets/VN2/photo-02.jpg") },
                { src: require("@/assets/VN2/photo-03.jpg") },
                { src: require("@/assets/VN2/photo-04.jpg") },
                { src: require("@/assets/VN2/photo-05.jpg") },
                { src: require("@/assets/VN2/photo-06.jpg") },
              ],
            },
            {
              nameProyect: "GIMNASIO Y SALONES DE USO MÚLTPLE",
              subtitle: "VILLA DE LAS NIÑOS",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2016",
              location: "Valle de Amarateca, Francisco Morazán",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  980.00 m² (10,548.63 pie²)",
              //               textInfo: `Construcción de edificio para Gimnasio de usos múltiples con los siguientes espacios:
              // Área de Gimnasio con capacidad para dos canchas polideportivas, escenario que incluye área de cambiadores y cabina de sonido, cuarto eléctrico y bodega, salón para danza y orquestina.

              // Cimentación con zapatas aisladas y tensores de concreto reforzado, pedestales de concreto columnas principales metálicas y estructura de techos con armaduras de perfiles WF, clavadores de canaleta y cubierta con lámina aluzinc prepintada color blanco. Paredes de bloque de concreto, repelladas, pulidas y pintadas, enchapes interiores con cerámica de color y enchapes exteriores con piedra aserrada. Pisos de terrazo en área de gimnasio, y de cerámica y concreto aparente en área de bodegas y salones. Instalaciones electromecánicas e hidrosanitarias completas.

              // `,
              price: "Valor de la construcción: $ 665,000.00",
              galeryProyect: [
                { src: require("@/assets/VN3/photo-01.jpg") },
                { src: require("@/assets/VN3/photo-02.jpg") },
                { src: require("@/assets/VN3/photo-03.jpg") },
                { src: require("@/assets/VN3/photo-04.jpg") },
                { src: require("@/assets/VN3/photo-05.jpg") },
                { src: require("@/assets/VN3/photo-06.jpg") },
                { src: require("@/assets/VN3/photo-07.jpg") },
              ],
            },
            {
              nameProyect: "EDIFICIO DE TALLERES",
              subtitle: "VILLA DE LAS NIÑOS",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2016",
              location: "Valle de Amarateca, Francisco Morazán",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total: 951.00m² (10,236.48 pie²)",
              //               textInfo: `CConstrucción de Edificio de talleres que consta de los siguientes ambientes: 
              // Primer Nivel: Talleres de Electricidad, Electrónica con almacén para equipos y cuarto eléctrico. Segundo Nivel: Sala de Computadoras, Idiomas, Aulas para estudiantes y cubo de escaleras.

              // Estructura de concreto reforzado acabado expuesto con zapatas aisladas y tensores de cimentación, columnas y vigas de concreto aparente, entrepiso con joists metálicos con lámina losacero y toppping de concreto. Estructura de techocon armaduras metálicas de ángulo, clavadores de canaleta y cubierta de lámina de aluzinc prepintada color blanco. Losa de techo aligerada de concreto para acomodar unidades de A/C. Paredes de bloque repelladas, pulidas y pintadas, enchapes interiores con cerámica de color y enchapes exteriores con piedra aserrada. Pisos de porcelanato en aulas y concreto aparente acabado afinado en área de talleres, Ventanas de PVC color blanco. Instalaciones electromecánicas e hidrosanitarias completas, incluye red de datos y sonido.

              // `,
              price: "Valor de la construcción: $ 763,000.00",
              galeryProyect: [
                { src: require("@/assets/VN4/photo-01.jpg") },
                { src: require("@/assets/VN4/photo-02.jpg") },
                { src: require("@/assets/VN4/photo-03.jpg") },
                { src: require("@/assets/VN4/photo-04.jpg") },
                { src: require("@/assets/VN4/photo-05.jpg") },
                { src: require("@/assets/VN4/photo-06.jpg") },
                { src: require("@/assets/VN4/photo-07.jpg") },
              ],
            },
            {
              nameProyect: "EAGLES NEST",
              subtitle: "EDIFICIO ACADÉMICO Y PLAN MAESTRO",
              type: null,
              year: "2016",
              location: "San José de la Vega, Comayagüela M.D.C.",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total: 4,000 m² (43,055.60 pie²)",
              //               textInfo: `Construcción de edificio para Eagles Nest que consta con los siguientes espacios: 
              // Primer Nivel: 11 aulas, área de secretaría y dirección, cuarto eléctrico y módulo de baños. Segundo Nivel: 8 aulas, salón de actividades prácticas, laboratorio de cómputo, área de maestros, área de oficinas y módulos de baños.

              // Cimentación de zapatas aisladas y corridas, tensores y vigas de rigidez, pedestales de concreto y estructura principal con columnas y vigas metálicas tipo WF, losas de entrepiso con joists metálicos con lámina losacero y topping de concreto. Estructura de techo con armaduras metálicas de ángulo, clavadores de canaleta y cubierta de lámina de aluzinc prepintada color blanco. Losa de techo de concreto monolítico para acomodar unidades de A/C y tanque elevado de concreto. Cielos falsos combinación de tablayeso y de suspensión de fibra mineral. Paredes de bloque tipo “Split-face” en exterior y repelladas, pulidas y pintadas en interior, enchapes de cerámica. Pisos de cerámica de alto tráfico. Ventanas de PVC color blanco. Instalaciones electromecánicas e hidrosanitarias completas, incluye red de datos y sonidos. 
              // `,
              price: "Valor de la Construcción: $ 1,065,000.00",
              galeryProyect: [
                { src: require("@/assets/BSC/photo-01.jpg") },
                { src: require("@/assets/BSC/photo-02.jpg") },
                { src: require("@/assets/BSC/photo-03.jpg") },
                { src: require("@/assets/BSC/photo-04.jpg") },
                { src: require("@/assets/BSC/photo-05.jpg") },
                { src: require("@/assets/BSC/photo-06.jpg") },
                { src: require("@/assets/BSC/photo-07.jpg") },
                { src: require("@/assets/BSC/photo-08.jpg") },
              ],
            },
            {
              nameProyect: "NIDO DE ÁGUILAS-EAGLES NEST",
              subtitle: "PLAN MAESTRO",
              type: null,
              year: "2016",
              location: "Comayagua, Comayagua",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total: 14,250.00 m² (153,385.72 pie²)",
              //               textInfo: `Plan maestro para el desarrollo de un complejo  educativo en los niveles de pre-básico, primaria y  secundaria, en los programas de educación  convencional y bilingüe.
              // El proyecto se desarrolla en varias etapas de  construcción. El campus constará con: muro  perimetral y acceso controlado con garita de  vigilancia, estacionamiento para personal, visitas y  autobuses, área de mantenimiento y equipos, área de  equipos hidro-neumáticos, cisterna, bodegas, talleres  de mantenimiento preventivo, área para personal de  transporte, sub-estación eléctrica con transformador  de 500KVA, transfer automático y generador.
              // Plaza de acceso y banderas, eje de circulación  principal (José Cecilio del Valle).
              // Edificio de Administración y Dirección Académica, de  cuatro niveles, incluye atención de padres de familia,  tienda escolar, enfermería y área de apartamentos de  docentes.
              // Edificio de Pre-básico, de un nivel, plaza central  cubierta (multi-usos), diez aulas para Nursery, Pre-  Kinder y Kinder, Oficina de dirección académica,  espacios de pedagógicos, centro de estimulación  temprana, área de juegos, divididos por niveles de  escolaridad.
              // Edificio de primaria y secundaria, de tres niveles,  plazas centrales cubiertas (multi-usos), veinticuatro  aulas, laboratorios de química, audio-visuales, música,  computación, biblioteca, módulos de baños para  primaria y secundaria.
              // Edifcio de Gimnasio, salones múltiples, dos canchas  polideportivas, escenario y vestidores.
              // Piscina semi-olímpica, vestidores y cuartos de
              // máquinas.
              // `,
              price: "Valor de la Construcción: $ 8,164,116.00",
              galeryProyect: [
                { src: require("@/assets/NIDO/photo-02.jpg") },
                { src: require("@/assets/NIDO/photo-03.jpg") },
                { src: require("@/assets/NIDO/photo-04.jpg") },
                { src: require("@/assets/NIDO/photo-05.jpg") },
                { src: require("@/assets/NIDO/photo-01.jpg") },
              ],
            },
          ],
        },
        {
          title: "COMERCIAL/INDUSTRIAL",
          proyect: [
            {
              nameProyect: "PLAZA CIUDAD NUEVA ETAPA I",
              subtitle: "PLAZA COMERCIAL",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2021",
              location: "Ciudad Nueva, Comayagüela M.D.C",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  3,500.00 m² (37,673.69 pie²)",
              //               textInfo: `Construcción de edificio comercial, consiste de 23 locales y una ofi-bodega en primer etapa.

              // Cimentación de zapatas aisladas y corridas, tensores y vigas de rigidez, pedestales de concreto y estructura principal con columnas y vigas metálicas tipo WF, losas de entrepiso con joists metálicos con lámina losacero y topping de concreto. Estructura de techo con armaduras metálicas de ángulo, clavadores de canaleta y cubierta de lámina de aluzinc pre-pintada color blanco. Losa de techo de concreto monolítico para acomodar unidades de A/C y tanque elevado de concreto. Paredes de concreto visto en exterior, enchapes y pisos de porcelanato.
              // Ventanas de PVC color: negro. Instalaciones electromecánicas e hidrosanitarias completas, incluye red de datos y sonidos. 
              // `,
              price: "Valor de la Construcción: $ 1,025,000.00",
              galeryProyect: [
                { src: require("@/assets/CIU1/poortada.jpg") },
                // { src: require("@/assets/CIU1/photo-01.jpg") },
                // { src: require("@/assets/CIU1/photo-02.jpg") },
                { src: require("@/assets/CIU2/photo-03.jpg") },
                { src: require("@/assets/CIU1/photo-03.jpg") },
                { src: require("@/assets/CIU1/photo-04.jpg") },
              ],
            },
            {
              nameProyect: "PLAZA CIUDAD NUEVA ETAPA II",
              subtitle: "PLAZA COMERCIAL",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2021",
              location: "Ciudad Nueva, Comayagüela M.D.C",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "",
              //               textInfo: `Construcción de edificio comercial, consiste de 33 locales, una ofi-bodega y 85 parqueos.

              // Cimentación de zapatas aisladas y corridas, tensores y vigas de rigidez, pedestales de concreto y estructura principal con columnas y vigas metálicas tipo WF, losas de entrepiso con joists metálicos con lámina losacero y topping de concreto. Estructura de techo con armaduras metálicas de ángulo, clavadores de canaleta y cubierta de lámina de aluzinc pre-pintada color blanco. Losa de techo de concreto monolítico para acomodar unidades de A/C y tanque elevado de concreto. Paredes de concreto visto en exterior, enchapes y pisos de porcelanato.
              // Ventanas de PVC color: negro. Instalaciones electromecánicas e hidrosanitarias completas, incluye red de datos y sonidos. 
              // `,
              price: "Valor de la Construcción: $ ---",
              galeryProyect: [
                // { src: require("@/assets/CIU2/photo-01.jpg") },
                // { src: require("@/assets/CIU2/photo-06.jpg") },
                { src: require("@/assets/CIU2/portada.jpg") },
                { src: require("@/assets/CIU2/photo-09.jpg") },
                { src: require("@/assets/CIU2/photo-10.jpg") },
                { src: require("@/assets/CIU2/photo-02.jpg") },

                // { src: require("@/assets/CIU2/photo-04.jpg") },
                { src: require("@/assets/CIU2/photo-05.jpg") },
                { src: require("@/assets/CIU2/photo-07.jpg") },
              ],
            },
            {
              nameProyect: "WOLOZNY",
              subtitle: "EDIFICIO COMERCIAL ",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "1996",
              location: "Zona El Molino, Tegucigalpa M.D.C",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total: 408.00 m² (5,170.00 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 163,700.00",
              galeryProyect: [
                { src: require("@/assets/wolozny/photo-01.jpg") },
                { src: require("@/assets/wolozny/photo-02.jpg") },
              ],
            },
            {
              nameProyect: "NAVE VESTIMODA",
              subtitle: "EDIFICIO COMERCIAL ",
              type: "DISEÑO Y CONSULTORÍA",
              year: "1997",
              location: "Salida a Valle de Ángeles, Tegucigalpa M.D.C",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  1,558.00 m² (16,780.00 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 244,385.00",
              galeryProyect: [
                { src: require("@/assets/nave/photo-01.jpg") },
                { src: require("@/assets/nave/photo-02.jpg") },
              ],
            },
            {
              nameProyect: "YUDE CANAHUATI",
              subtitle: "EDIFICIO COMERCIAL ",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "1999",
              location: "Ubicación:  Comayaguela, M.D.C",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  1,020.00 m² (10,985.00 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 211,765.00",
              galeryProyect: [
                { src: require("@/assets/yude/photo-01.jpg") },
                { src: require("@/assets/yude/photo-02.jpg") },
              ],
            },
            // {
            //   nameProyect: "DO IT CENTER",
            //   subtitle: "EDIFICIO COMERCIAL ",
            //   type: "DISEÑO",
            //   year: "2000",
            //   location: "Comayaguela, M.D.C",
            //   desing: "Arq. Héctor J. Gutiérrez Drechsel.",
            //   constuction: "Gutiérrez & Asociados.",
            //   area: "Área Total:  2,450.00 m² (26,386.50 pie²)",
            //   textInfo: ``,
            //   price: "Valor de la Construcción: $ 522,600.00",
            //   galeryProyect: [{ src: require("@/assets/indufesa-01.jpg") }],
            // },
            {
              nameProyect: "EDIFICIO DE OFICINAS ",
              subtitle: "",
              // subtitle: " GUTIÉRREZ Y ASOCIADOS",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2006",
              location: "Zona La Cañada, Tegucigalpa, M.D.C",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  390.00 m² (4,200.30 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 120,000.00",
              galeryProyect: [
                { src: require("@/assets/OFFIC/photo-01.jpg") },
                { src: require("@/assets/OFFIC/photo-02.jpg") },
                // { src: require("@/assets/OFFIC/photo-03.jpg") },
              ],
            },
            {
              nameProyect: "HENRY ARÉVALO FUENTES",
              subtitle: "AGENCIA ADUANERA ",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2007",
              location: "San Pedro Sula, Cortés.",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  275.00 m² (2,959.83 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 105,000.00",
              galeryProyect: [
                { src: require("@/assets/ADUA/photo-01.jpg") },
                { src: require("@/assets/ADUA/photo-02.jpg") },
              ],
            },
            {
              nameProyect: "QUICK PARTS, QUICK LANE",
              subtitle: "EDIFICIO COMERCIAL  ",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2011",
              location: "Tegucigala M.D.C",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  1,250.00 m² (13,453.75 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 215,000.00",
              galeryProyect: [
                { src: require("@/assets/QUICK/photo-01.jpg") },
                { src: require("@/assets/QUICK/photo-02.jpg") },
              ],
            },
            {
              nameProyect: "MITSUBISHI MOTORS",
              subtitle: "SALA DE VENTAS EXCEL AUTOMOTRIZ ",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2017",
              location: "La Hacienda, Tegucigalpa, M.D.C",
              desing: "DCI (Diseño, Construcción e Inversiones).",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  940.00 m² (10,118.08 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 186,000.00",
              galeryProyect: [{ src: require("@/assets/MIT/photo-01.jpg") }],
            },
            {
              nameProyect: "HONDA",
              subtitle: "SALA DE VENTAS EXCEL AUTOMOTRIZ ",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2017",
              location: "Col. Miramontes, Tegucigalpa, M.D.C",
              desing: "DCI (Diseño, Construcción e Inversiones).",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  410.00 m² (4,391.68 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 66,500.00",
              galeryProyect: [{ src: require("@/assets/H/photo-01.jpg") }],
            },
          ],
        },
        {
          title: "RESIDENCIAL",
          proyect: [
            {
              nameProyect: "VILLAS DEL MOLINO",
              subtitle: "DISEÑO Y CONSTRUCCIÓN",
              type: "CONSTRUCCIÓN",
              year: "2006",
              location: "Zona El Molino, Tegucigalpa M.D.C ",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  6,030.00 m² (64,906.40 pie²)",
              //               textInfo: `Construcción de un complejo residencial de 24 viviendas tipo circuito cerrado con muros perimetrales, acceso controlado mediante pórtico y caseta de vigilancia, calles vehiculares pavimentadas privadas con derecho de vía de 11 mts incluyendo acera y área verde en ambos lados.
              // El proyecto esta constituido de estructuras de concreto reforzado y mampostería, de dos niveles, cimentación de zapatas aisladas y corridas, entrepisos metálicos con forros de lámina Aluzinc y fundición de concreto, techos de estructura metálica con cubierta de lámina imitación teja pre-pintada, molduras decorativas, acabados en paredes con repellos, pulidos y pintados, puertas prefabricadas americanas, ventanas de aluminio anodizado blanco y vidrio claro con secciones corredizas, cielos de tablayeso, pisos de cerámica, revestimientos de cerámicas en baños, muebles de cocina pre-fabricado americano de fibra de madera, instalaciones eléctricas completas con ductería Conduit pvc, instalaciones hidro-sanitarias con cisterna para almacenamiento de agua con capacidad de 2,400 gls., Y equipo hidro-neumático de ½ hp.
              // `,
              price: "Valor de la Construcción: $ 2,280,000.00",
              galeryProyect: [
                { src: require("@/assets/molino/photo-01.jpg") },
                { src: require("@/assets/molino/photo-02.jpg") },
                { src: require("@/assets/molino/photo-03.jpg") },
                { src: require("@/assets/molino/photo-04.jpg") },
                { src: require("@/assets/molino/photo-05.jpg") },
              ],
            },
            // {
            //   // nameProyect: "RAMIREZ ERAZO",
            //   nameProyect: "",
            //   subtitle: "RESIDENCIA",
            //   type: "DISEÑO Y CONSTRUCCIÓN",
            //   year: "1997",
            //   location: "Zona El Molino, Tegucigalpa M.D.C ",
            //   desing: "Arq. Héctor J. Gutiérrez Drechsel.",
            //   constuction: "Gutiérrez & Asociados.",
            //   area: "Área Total:  310.00 m² (3,335.00 pie²)",
            //   textInfo: ``,
            //   price: "Valor de la Construcción: $ 103,000.00",
            //   galeryProyect: [
            //     { src: require("@/assets/RESI/photo-01.jpg") },
            //     { src: require("@/assets/RESI/photo-02.jpg") },
            //   ],
            // },
            // {
            //   // nameProyect: "GUADALUPE QUESADA",
            //   nameProyect: "",
            //   subtitle: "RESIDENCIA",
            //   type: "DISEÑO Y CONSTRUCCIÓN",
            //   year: "1999",
            //   location: "Colonia Loma Linda. Tegucigalpa M.D.C  ",
            //   desing: "Arq. Héctor J. Gutiérrez Drechsel.",
            //   constuction: "Gutiérrez & Asociados.",
            //   area: "Área Total:  380.00 m² (4,093.70 pie²)",
            //   textInfo: ``,
            //   price: "Valor de la Construcción: $ 146,898.00",
            //   galeryProyect: [
            //     { src: require("@/assets/GUADA/photo-03.jpg") },
            //     { src: require("@/assets/GUADA/photo-01.jpg") },
            //     { src: require("@/assets/GUADA/photo-02.jpg") },
            //   ],
            // },
            {
              nameProyect: "",
              // nameProyect: "GUTIÉRREZ ARÉVALO",
              subtitle: "RESIDENCIA",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2002",
              location: "Monte Real, Mateo, M.D.C ",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  310.00 m² (3,336.53 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 375,000.00",
              galeryProyect: [
                { src: require("@/assets/CASA01/photo-01.jpg") },
                { src: require("@/assets/CASA01/photo-02.jpg") },
              ],
            },
            {
              nameProyect: "",
              // nameProyect: "GUTIÉRREZ AVENDAÑO",
              subtitle: "RESIDENCIA",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2006",
              location: "Monte Real, Mateo, M.D.C ",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  270.00 m² (2,906.01 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 245,000.00",
              galeryProyect: [
                { src: require("@/assets/CASA02/photo-01.jpg") },
                { src: require("@/assets/CASA02/photo-02.jpg") },
              ],
            },
            // {
            //   nameProyect: "",
            //   // nameProyect: "LARDIZABAL SIERRA",
            //   subtitle: "RESIDENCIA",
            //   type: "DISEÑO Y CONSTRUCCIÓN",
            //   year: "2009",
            //   location: "El Hatillo, Tegucigalpa M.D.C",
            //   desing: "Arq. Héctor J. Gutiérrez Drechsel.",
            //   constuction: "Gutiérrez & Asociados.",
            //   area: "Área Total:  250.00 m² (2,690.78 pie²)",
            //   textInfo: ``,
            //   price: "Valor de la Construcción: $ 180,000.00",
            //   galeryProyect: [
            //     { src: require("@/assets/CASA03/photo-01.jpg") },
            //     { src: require("@/assets/CASA03/photo-02.jpg") },
            //   ],
            // },
            {
              // nameProyect: "FUNES GUEVARA",
              nameProyect: "",
              subtitle: "RESIDENCIA",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2007",
              location: "El Hatillo, Tegucigalpa M.D.C",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  350.00 m² (3,766.00 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 326,000.00",
              galeryProyect: [
                { src: require("@/assets/CASA04/photo-02.jpg") },
                { src: require("@/assets/CASA04/photo-01.jpg") },
              ],
            },
            {
              // nameProyect: "LILIANA CABAÑAS",
              nameProyect: "",
              subtitle: "RESIDENCIA",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2007",
              location: "Valle de Ángeles, Francisco Morazán",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  250.00 m² (2,690.78 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 98,000.00",
              galeryProyect: [
                { src: require("@/assets/CASA05/photo-02.jpg") },
                { src: require("@/assets/CASA05/photo-01.jpg") },
                { src: require("@/assets/CASA08/photo-02.jpg") },
                { src: require("@/assets/CASA08/photo-01.jpg") },
              ],
            },
            {
              // nameProyect: "LÓPEZ FLORES",
              nameProyect: "",
              subtitle: "RESIDENCIA",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2007",
              location: "Valle de Ángeles, Francisco Morazán",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  515.00 m² (5,542.95 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 270,000.00",
              galeryProyect: [
                { src: require("@/assets/CASA06/photo-02.jpg") },
                { src: require("@/assets/CASA06/photo-01.jpg") },
              ],
            },
            
            // {
            //   nameProyect: "",
            //   // nameProyect: "LOPEZ FLORES ÁREA SOCIAL",
            //   subtitle: "RESIDENCIA",
            //   type: "DISEÑO Y CONSTRUCCIÓN",
            //   year: "2007",
            //   location: "Valle de Ángeles, Francisco Morazán",
            //   desing: "Arq. Héctor J. Gutiérrez Drechsel.",
            //   constuction: "Gutiérrez & Asociados.",
            //   area: "Área Total:  250.00 m² (2,690.78 pie²)",
            //   textInfo: ``,
            //   price: "Valor de la Construcción: $ 280,000.00",
            //   galeryProyect: [
            //     { src: require("@/assets/CASA08/photo-02.jpg") },
            //     { src: require("@/assets/CASA08/photo-01.jpg") },
            //   ],
            // },
            {
              nameProyect: "",
              // nameProyect: "JEFF FLORES",
              subtitle: "RESIDENCIA",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2014",
              location: "Valle de Ángeles, Francisco Morazán",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  210.00 m² (2,260.23 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 90,000.00",
              galeryProyect: [
                { src: require("@/assets/CASA09/photo-02.jpg") },
                { src: require("@/assets/CASA09/photo-01.jpg") },
              ],
            },
           
            // {
            //   // nameProyect: "OTERO NÚÑEZ",
            //   nameProyect: "",
            //   subtitle: "RESIDENCIA",
            //   type: "DISEÑO Y CONSTRUCCIÓN",
            //   year: "2017",
            //   location: "Las Lomas, Tegucigalpa M.D.C",
            //   desing: "Arq. Héctor J. Gutiérrez Drechsel.",
            //   constuction: "Gutiérrez & Asociados.",
            //   area: "Área Total:  210.00 m² (2,260.23 pie²)",
            //   textInfo: ``,
            //   price: "Valor de la Construcción: $ 70,950.00",
            //   galeryProyect: [
            //     { src: require("@/assets/CASA11/photo-01.jpg") },
            //     { src: require("@/assets/CASA11/photo-02.jpg") },
            //   ],
            // },
            // {
            //   // nameProyect: "REYES PENAGOS",
            //   nameProyect: "",
            //   subtitle: "RESIDENCIA",
            //   type: "DISEÑO Y CONSTRUCCIÓN",
            //   year: "2020",
            //   location: "Villas del Molino, Tegucigalpa M.D.C",
            //   desing: "Arq. Héctor J. Gutiérrez Drechsel.",
            //   constuction: "Gutiérrez & Asociados.",
            //   area: "Área Total: 240.00 m² (2,475.49 pie²)",
            //   textInfo: ``,
            //   price: "Valor de la Construcción: $ 53,441.29",
            //   galeryProyect: [
            //     { src: require("@/assets/CASA12/photo-01.jpg") },
            //     { src: require("@/assets/CASA12/photo-02.jpg") },
            //     { src: require("@/assets/CASA12/photo-03.jpg") },
            //     { src: require("@/assets/CASA12/photo-04.jpg") },
            //     { src: require("@/assets/CASA12/photo-05.jpg") },
            //   ],
            // },
            {
              // nameProyect: "REMODELACION TOME",
              nameProyect: "",
              subtitle: "RESIDENCIA",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2021",
              location: "Tegucigalpa M.D.C",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total: 240.00 m² (2,475.49 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 53,441.29",
              galeryProyect: [
                { src: require("@/assets/CASA13/photo-01.jpg") },
                { src: require("@/assets/CASA13/photo-02.jpg") },
              ],
            },
          ],
        },
        {
          title: "TURÍSTICO",
          proyect: [
            {
              nameProyect: "MAIN HOUSE INDURA  ",
              subtitle: "",
              type: "CONSTRUCCIÓN",
              year: "2012",
              location: "Tela, Atlántida",
              desing: "Korbi Karp Architecture",
              constuction: "Gutiérrez & Asociados.",
              area: "ÁREA TOTAL: 4,500.00 m² ",
              //               textInfo: `Construcción del Main House del Hotel Boutique del complejo Indura Beach & Golf Resort, que cuenta con un edificio de dos niveles. Distribuido en el Primer Nivel un Lobby, Restaurante, Salón de Eventos, Pasillos de conexión, Fuentes y Espejos de Agua, y en El Sótano, con oficinas Administrativas, Locales Comerciales, Baños para el Público, Gimnasio, Cinema, Cocinas, Bodegas, Cuarto Eléctrico, Cuarto Bomba y Cuarto UMA para A/A, Baños para Empleados, Pasillos de Servicio, y Cubos para elevadores.

              // Cimentación con pilotes de concreto reforzado, estructura principal de columnas, vigas y estructura de techos con perfiles metálicos WF. Pisos en área de recepción concreto aparente pulidoy porcelanato simulación madera, de alto tráfico. Recubrimientos: estructura principal con madera, paredes con vinil y porcelanato. Techos con lamina de aluzinc color natural cubierta por hojas de palmera seca.Ventanas de PVC color café. Instalaciones electromecánicas e hidrosanitarias completas, incluye red de datos y sonido.

              // `,
              price: "Valor de la Construcción; $ 6,467,132.85",
              galeryProyect: [
                { src: require("@/assets/MAIN/photo--01.jpg") },
                { src: require("@/assets/MAIN/photo--02.jpg") },
                { src: require("@/assets/MAIN/photo--03.jpg") },
                { src: require("@/assets/MAIN/photo--04.jpg") },
                { src: require("@/assets/MAIN/photo--05.jpg") },
                { src: require("@/assets/MAIN/photo-01.jpg") },
                { src: require("@/assets/MAIN/photo-02.jpg") },
                { src: require("@/assets/MAIN/photo-03.jpg") },
              ],
            },
            {
              nameProyect: "GUALIQUEME",
              subtitle: "HOTEL y CLUB",
              type: "DISEÑO Y SUPERVISIÓN",
              year: "1993",
              location: "Choluteca, Choluteca.",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "",
              area: "Área Total:  2,150.00 m² (23,155.50 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 2,125,000.00",
              galeryProyect: [
                { src: require("@/assets/GUA/photo-01.jpg") },
                { src: require("@/assets/GUA/photo-02.jpg") },
                { src: require("@/assets/GUA/photo-03.jpg") },
              ],
            },
            {
              nameProyect: "",
              subtitle: "RESIDENCIA",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2007",
              location: "Shore’s Plantation, Tela, Atlántida.",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  230.00 m² (2,475.49 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 147,419.00",
              galeryProyect: [
                { src: require("@/assets/CASA07/photo-02.jpg") },
                { src: require("@/assets/CASA07/photo-01.jpg") },
              ],
            },
            {
              nameProyect: "",
              // nameProyect: "CASA DE PLAYA GUTIÉRREZ",
              subtitle: "RESIDENCIA",
              type: "DISEÑO Y CONSTRUCCIÓN",
              year: "2014",
              location: "San Juán, Tela, Atlántida.",
              desing: "Arq. Héctor J. Gutiérrez Drechsel.",
              constuction: "Gutiérrez & Asociados.",
              area: "Área Total:  120.00 m² (1,291.56 pie²)",
              textInfo: ``,
              price: "Valor de la Construcción: $ 120,000.00",
              galeryProyect: [
                { src: require("@/assets/CASA10/photo-01.jpg") },
                { src: require("@/assets/CASA10/photo-02.jpg") },
                { src: require("@/assets/TELA/tela.jpg") },
                { src: require("@/assets/CASA10/photo-03.jpg") },
                { src: require("@/assets/CASA10/photo-04.jpg") },
                { src: require("@/assets/TELA/tela-1.jpg") },
                { src: require("@/assets/TELA/tela-0002.jpg") },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    setInfoOpenDialog(item) {
      this.setInfo = item;
      this.dialogInfo = true;
    },
    validate() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        axios.defaults.headers.post["Content-Type"] = "application/json";
        // .post("https://formsubmit.co/ajax/oficina@gutierrezyasociadoshn.com", {
        axios
          .post(
            "https://formsubmit.co/ajax/oficina@gutierrezyasociadoshn.com",
            {
              name: this.form.name,
              email: this.form.email,
              _subject: "Nuevo Mensaje desde Web",
              phone: this.form.phone,
              message: this.form.message,
              _captcha: false,
              _next: "https://www.gutierrezyasociadoshn.com/tanks",
            }
          )
          .then((response) => {
            // console.log(response)
            if (response.status == 200) {
              // console.log(response.data.success)
              this.$router.push("/tanks");
            }
          })
          .catch((error) => console.log(error));
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";

.home {
  display: flex;
  justify-content: flex-start;
  background-image: url("../assets/trama-04.svg");
  background-repeat: repeat-y;
  background-size: contain;
  background-position: center;
  flex-direction: column;
  align-items: center;
}

// html[data-scroll="0"] {
//   .wrap-home {
//     height: 100vh;
//     width: 100%;
//     margin-top: 0;
//     // background-image: url("../assets/home-1.jpeg");
//     background-size: cover;
//     background-position: center;
//     background-color: #ff5900;
//     display: flex;
//     justify-content: center;

//     align-items: center;
//   }
// }
.wrap-home {
  transition: 0.3s ease-in-out;
  // margin-top: 100px;
  // height: calc(100vh - 100px);
  // width: calc(100vw - 100px);
  height: 100vh;
  width: 100vw;
  // background-image: url("../assets/home-1.jpeg");
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: center;
  position: relative;

  align-items: center;

  .filter {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.527);
    position: absolute;
    // mix-blend-mode: multiply;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 2;
  }

  .logo-bien {
    z-index: 50;
  }
}

.mision-vision {}

.color-primary {
  color: #d60303;
}

.box-text {
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.409);
}

.imframe {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.22);
}

.xs-block {
  display: none;
}

@include responsive(mobile) {
  .xs-text-center {
    text-align: center;
  }

  .xs-block {
    display: block;
  }
}
</style>
