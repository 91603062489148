<template>
  <v-footer dark padless color="primary">
    <v-container>
      <div class="lighten-1 white--text text-center">
        <v-card-text>
          <v-btn
            v-for="(i,index) in icons"
            :key="index"
            class="mx-4 white--text"
            icon
            target="_blank"
           :href="i.link"
          >
            <v-icon size="24px">
              {{ i.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <!-- <v-card-text class="white--text">
          Phasellus feugiat arcu sapien, et iaculis ipsum elementum sit amet.
          Mauris cursus commodo interdum. Praesent ut risus eget metus luctus
          accumsan id ultrices nunc. Sed at orci sed massa consectetur dignissim
          a sit amet dui. Duis commodo vitae velit et faucibus. Morbi vehicula
          lacinia malesuada. Nulla placerat augue vel ipsum ultrices, cursus
          iaculis dui sollicitudin. Vestibulum eu ipsum vel diam elementum
          tempor vel ut orci. Orci varius natoque penatibus et magnis dis
          parturient montes, nascetur ridiculus mus.
        </v-card-text> -->

        <v-divider></v-divider>

        <v-card-text class="white--text flex-center fl">
          <span>{{ new Date().getFullYear() }} —</span>
          <img
            src="@/assets/G-06.svg"
            class="ml-2"
            width="20px"
            height="20px"
            alt=""
            srcset=""
          />
        </v-card-text>
      </div>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  name: "footerComp",
  data() {
    return {
      icons: [
        {
          icon: "mdi-facebook",
          link: "https://www.facebook.com/gutierrezyasoc/",
        },

        {
          icon: "fa-brands fa-behance",
          link: "https://www.behance.net/gutierrasociad",
        },
        {
          icon: "mdi-instagram",
          link: "https://www.instagram.com/gutierrezyasoc/",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.footer {
  height: 300px;
  background-color: #d60303;
}
.fl {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>