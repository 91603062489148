<template>
  <transition name="fade">
    <div
      v-if="show"
      class="loader"
      :class="{ 'color-bg-compras': !isMenu, 'color-bg-menu': isMenu }"
      :style="`background-color: ${colorBG};`"
    >
      <div class="loader" :style="`background-color: ${colorBG};`">
        <svg
        class="logo-svg"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 553.4 553.4"
          style="enable-background: new 0 0 553.4 553.4"
          xml:space="preserve"
        >
          <g id="Logo_Rojo_00000019675770815980856990000014120202130633714351_">
            <g>
              <path
                class="st0"
                d="M422.6,276.8l-145.9-0.1l2.1,146c-0.7,0-1.4,0-2.1,0c-40.4,0-76.9-16.4-103.4-42.9l-84.2,83.8
			c47.9,48.1,114.3,77.9,187.5,77.9c146.2,0,264.7-118.5,264.7-264.6H422.6z"
              />
              <path
                class="st0"
                d="M130.8,276.8c0-80.6,65.3-145.9,145.9-145.9c40.4,0,77,16.5,103.5,43.1l84.3-83.8
			C416.5,41.9,350.1,12,276.7,12C130.5,12,12,130.5,12,276.7c0,0,0,0.1,0,0.1H130.8z"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
  </transition>
</template>

<script>
// import Lottie from "./Lottie";
// import { mapGetters } from "vuex";
// // import store from "@/store/index.js";
// import * as preloader from "@/assets/Lottie/preloader.json";
// import * as compras from "@/assets/Lottie/compras.json";
// import * as ocho from "@/assets/Lottie/data.json";
// import * as Caminanding from "@/assets/Lottie/caminando.json";
// import * as deleteOrder from "@/assets/Lottie/delete.json";

export default {
  name: "Preloader",
  components: {
    // lottie: Lottie,
  },
  props: {},
  data() {
    return {
      colorBG: "#d60303",
      color: "",
      isMenu: true,
      anim: null,

      show: true,
      show_lottie: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.show_lottie = true;
        this.showLoader();
      }, 500);
    });
  },
  methods: {
    showLoader() {
      window.onload = setTimeout(() => {
        this.show = false;
      }, 3000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
$BG: #d60303;

@import "@/_responsive.scss";
.logo-svg{
  width: 200px;
  height: 200px;
}
.loader {
  width: 100vw;
  height: 100vh;
  // background-color: $BG;

  z-index: 1000 !important;
  display: flex;
  pointer-events: none;
  //  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: hidden;
  position: fixed;
  flex-direction: column;
  .lottie-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
  }
}
.color-bg-menu {
  background-image: radial-gradient(
    circle at 50% 50%,
    #00cdbc,
    #00c3cd
  ) !important;
}
.color-bg-compras {
  background-image: radial-gradient(
    circle at 50% 50%,
    #ffc634,
    #ffda7c
  ) !important;
}
@include responsive(mobile) {
  .lottie-content {
    width: 75% !important;
  }
}
@include responsive(desktop) {
  .lottie-content {
    width: 250px !important;
    // height: 100px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.by-ocho {
  position: absolute;
  bottom: 15vh;
}

.st0 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-miterlimit: 0;
  stroke-dashoffset: 0;
  stroke-dasharray:1000;
  animation: circle_stroke 2s ease-in forwards;
}

@keyframes circle_stroke {
  0% {
    stroke-dashoffset: 2000;
        fill: rgba(255, 255, 255, 0);
  }
  40%{
    fill: rgba(255, 255, 255, 0);

  }
  100% {
    stroke-dashoffset: 0;
    fill: #fff;
  }
}
</style>