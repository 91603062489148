<template>
  <v-card class="bg-card">
    <v-card-title style="color: #d60303">
      {{ info.nameProyect }}
      <v-btn
        color="primary"
        icon
        @click="$emit('closed')"
        outlined
        absolute
        right
        top
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle>
      {{ info.subtitle }}
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text>
      <v-container>
        <v-row class="my-6" dense>
          <v-col cols="12" sm="6" lg="6" md="6">
            <div style="position: sticky; top: 20px">
              <h1 style="color: #d60303">{{ info.nameProyect }}</h1>
              <h3>{{ info.subtitle }}</h3>
              <p>
                {{ info.type }}
              </p>
              <p>
                Año: <b> {{ info.year }}</b>
              </p>
              <p>
                Ubicación: <b>{{ info.location }}</b>
              </p>
              <p>
                Diseño: <b>{{ info.desing }}</b>
              </p>
              <p>
                Construcción: <b>{{ info.constuction }}</b>
              </p>
              <p>
                <b>
                  {{ info.area }}
                </b>
              </p>
              <p>{{ info.textInfo }}</p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="6" md="6">
            <v-row>
              <v-col cols="12">
                <v-img :src="valueSelect"> </v-img>
              </v-col>
              <v-col
                v-for="(i, index) in info.galeryProyect"
                :key="index"
                cols="6"
                @click="valueSelect = i.src"
                
              >
                <v-img :src="i.src" :class="{'border-select':valueSelect == i.src}"> </v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "infoProyect",
  props: ["info"],
  data: () => ({
    valueSelect: null,
  }),
  mounted() {
    console.debug(this.info);
    this.valueSelect = this.info.galeryProyect[0].src;
  },
};
</script>
<style lang="scss">
.bg-card {
  background-image: url("../assets/trama-04.svg");
  background-position: center;
  background-size: cover;
}
.stiky {
  position: sticky;
  top: 100px;
  background-color: aqua;
}
.bg-primary {
  background-color: aquamarine;
  height: 100%;
  position: sticky;
}
.border-select{
  border: solid 4px #d60303;
}
</style>