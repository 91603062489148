<template>
  <v-app>
    <preloader />
    <v-app-bar app color="primary" dark class="flex-center">

        
          <img
   
           height="100%"
  
            src="@/assets/logo-nav-05.svg"
        
         
          />
       
   
    </v-app-bar>

    <v-main class="">
      <router-view />
    </v-main>
    <footerComp />
  </v-app>
</template>

<script>
import footerComp from "@/components/footer.vue";
import preloader from "@/components/preloader.vue";
export default {
  name: "App",
  components: {
    footerComp,
    preloader
  },

  data: () => ({
    //
  }),
};
</script>
<style lang="scss" scoped>
.flex-center{
  width: 100%;

  display: flex;
  justify-content: center;
  justify-items: center;
}
</style>
