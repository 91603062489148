import Vue from 'vue';
import Vuetify from "vuetify/lib/framework";
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
      themes: {
        light: {
            primary: '#d60303',
            secondary: '#ff0d18',
            accent: '#f06a25',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107'
        },
        dark: {
            primary: '#d60303',
            secondary: '#132B54',
            accent: '#CC581F',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107'
        },
    },
    
  },
    lang: {
      locales: { es },
      current: 'es',
    },
  icons: {
    iconfont: 'fa',
  },
});
